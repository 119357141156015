import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("@/views/ForgotView.vue"),
  },
  {
    path: "/forgot-otp",
    name: "forgot-otp",
    component: () => import("@/views/ForgotOTPView.vue"),
  },
  {
    path: "/set-password/:id",
    name: "set-password",
    component: () => import("@/views/InitialPasswordView.vue"),
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("@/views/OTPView.vue"),
  },
  {
    path: "/app",
    name: "app",
    redirect: { name: "dashboard" },
    component: () => import("@/components/LayoutView.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/DashboardView.vue"),
      },
      {
        path: "profile/:id",
        name: "profile",
        component: () => import("@/views/ProfileView.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "404-errpr",
    component: () => import("@/views/NotFoundView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
