<template>
  <div class="alert-view">
    <v-alert border="top" :color="color" dark dismissible>
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "alert-view",
  props: {
    message: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
    timeout: {
      type: [Number, String],
      default: 0,
    },
  },
  mounted() {
    const _this = this;
    setTimeout(function () {
      _this.$emit("hide", _this.index);
    }, _this.timeout);
  },
};
</script>
