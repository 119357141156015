<template>
  <v-app id="app">
    <router-view />
    <div class="px-4 pt-4 custom-alerts">
      <AlertView
        v-for="(row, index) in errors"
        :key="index"
        :index="index"
        :message="row.message"
        color="red lighten-1"
        v-on:hide="hide_alert($event)"
        timeout="3000"
      ></AlertView>
      <AlertView
        v-for="(row, index) in success"
        :key="index"
        :index="index"
        :message="row.message"
        color="green lighten-1"
        v-on:hide="hide_alert($event)"
        timeout="3000"
      ></AlertView>
    </div>
  </v-app>
</template>

<style lang="scss">
// Main style scss
@import "assets/sass/style.vue";
</style>

<script>
import {
  ErrorEventBus,
  SuccessEventBus,
  ClearEventBus,
} from "@/core/lib/message.lib";
import AlertView from "@/components/AlertView";

export default {
  name: "main-app",
  data() {
    return {
      errors: [],
      success: [],
    };
  },
  components: {
    AlertView,
  },
  methods: {
    hide_alert(index) {
      this.errors.splice(index, 1);
      this.success.splice(index, 1);
    },
  },
  beforeDestroy() {
    ErrorEventBus.$off("set-error");
    SuccessEventBus.$off("set-message");
    ClearEventBus.$off("clear");
  },
  mounted() {
    ErrorEventBus.$on("set-error", (param) => {
      this.errors = param;
    });

    SuccessEventBus.$on("set-message", (param) => {
      this.success = param;
    });

    ClearEventBus.$on("clear", () => {
      this.errors = [];
      this.success = [];
    });
  },
};
</script>
