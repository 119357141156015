import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import ApiService from "@/core/services/api.services";
import MainSetting from "@/core/mixins/main.mixin";
import VueMask from "v-mask";

Vue.use(MainSetting);

Vue.use(VueMask);

Vue.config.productionTip = false;

Vue.prototype.$assetURL = (param) => {
  return `${process.env.VUE_APP_BASE_URL}${param}`;
};

// API service init
ApiService.init();

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
