import ApiService from "@/core/services/api.services";
import JwtService from "@/core/services/jwt.services";

// action types
export const LOGIN = "login";
export const REGISTER = "register";
export const FORGOT_PASSWORD = "forgot-password";
export const LOGOUT = "logout";
export const PROFILE = "profile";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_USER = "setUser";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";
export const SET_REGISTER = "setRegister";
export const SET_FORGOT = "setForgot";

const state = {
  errors: [],
  user: JwtService.getCurrentUser(),
  isAuthenticated: !!JwtService.getToken(),
  register: {},
  forgot: {},
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  getRegister(state) {
    return state.register;
  },
  getForgot(state) {
    return state.forgot;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/client", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [REGISTER](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.post("customer/auth/login", request)
        .then(({ data }) => {
          context.commit(SET_REGISTER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [FORGOT_PASSWORD](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.post("customer/auth/forgot", request)
        .then(({ data }) => {
          context.commit(SET_FORGOT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [PROFILE](context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("user/profile")
        .then(({ data }) => {
          context.commit(SET_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("auth/logout")
        .then(() => {
          context.commit(PURGE_AUTH);
          resolve(true);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          resolve(false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_REGISTER](state, output) {
    state.register = output;
  },
  [SET_FORGOT](state, output) {
    state.forgot = output;
  },
  [SET_USER](state, data) {
    state.user = data;
    JwtService.saveUser(data);
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.errors = {};
    JwtService.saveToken(data);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
