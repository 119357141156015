import { isObject, isEmpty, identity, pickBy, cloneDeep } from "lodash";
import { format } from "date-fns";

const validate_email = (email) => {
  //eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

class MainSetting {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          vrules: {
            required(val, field, isInteger) {
              if (isObject(val) && isEmpty(val)) {
                return `${field} is required`;
              }
              if (isInteger && parseInt(val) <= 0) {
                return `${field} is required.`;
              }
              return !!val || `${field} is required`;
            },
            valid_email(val, field) {
              if (val) {
                if (validate_email(val)) {
                  return true;
                }
                return `${field} must be valid`;
              }
              return true;
            },
            confirmPassword(val, field, old) {
              if (val) {
                val = val.toString();
                old = old.toString();
                if (val === old) {
                  return true;
                }
                return `${field} you entered don't match to new password`;
              }
              return true;
            },
          },
        };
      },
      methods: {
        deepEmpty(param) {
          return cloneDeep(pickBy(param, identity));
        },
        formatDateTime(dateTime) {
          return format(new Date(dateTime), "dd/MM/yyyy hh:mm a");
        },
        formatDateTimeRaw(dateTime) {
          return format(new Date(dateTime), "yyyy-MM-dd HH:mm");
        },
        formatDate(date) {
          return format(new Date(date), "dd/MM/yyyy");
        },
        formatDateRaw(date) {
          return format(new Date(date), "yyyy-MM-dd");
        },
        goBack() {
          this.$router.go(-1);
        },
        compressImage(file) {
          const _this = this;
          return new Promise((resolve, reject) => {
            const blobURL = URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onerror = function () {
              URL.revokeObjectURL(this.src);
              reject("Cannot load image");
            };
            img.onload = function () {
              URL.revokeObjectURL(this.src);
              // const [newWidth, newHeight] = _this.calculateSize(img, 2048, 1150);
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, img.width, img.height);
              canvas.toBlob(
                (blob) => {
                  resolve(blob);
                },
                "image/jpeg",
                0.6
              );
            };
          });
        },
        calculateSize(img, maxWidth, maxHeight) {
          let width = img.width;
          let height = img.height;

          // calculate the width and height, constraining the proportions
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }
          return [width, height];
        },
      },
      mounted() {},
      errorCaptured(err, vm, info) {
        console.log({ err, vm, info });
      },
    });
  }
}

export default new MainSetting();
