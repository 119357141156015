const ID_TOKEN_KEY = "access_token";
const ID_TOKEN_TYPE = "token_type";
const ID_TOKEN_EXPIRY = "expires_at";
const ID_CURRENT_USER = "_btusr_tmp";

const destroyAuth = () => {
  window.localStorage.clear();
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_TYPE);
  window.localStorage.removeItem(ID_TOKEN_EXPIRY);
  window.localStorage.removeItem(ID_CURRENT_USER);
};

export const isAuthenticated = () => {
  return !!window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getTokenType = () => {
  return window.localStorage.getItem(ID_TOKEN_TYPE);
};

export const getTokenExpiry = () => {
  return window.localStorage.getItem(ID_TOKEN_EXPIRY);
};

export const getCurrentUser = () => {
  return window.localStorage.getItem(ID_CURRENT_USER);
};

export const saveToken = ({ token_type, expires_in, access_token }) => {
  window.localStorage.setItem(ID_TOKEN_TYPE, token_type);
  window.localStorage.setItem(ID_TOKEN_KEY, access_token);
  window.localStorage.setItem(ID_TOKEN_EXPIRY, expires_in);
};

export const destroyToken = () => {
  destroyAuth();
};

export default {
  getToken,
  getTokenType,
  getTokenExpiry,
  getCurrentUser,
  saveToken,
  isAuthenticated,
  destroyToken,
};
