import ApiService from "@/core/services/api.services";
import ObjectPath from "object-path";

// action types
export const POST = "request-post";
export const PUT = "request-put";
export const GET = "request-get";
export const QUERY = "request-query";
export const PATCH = "request-patch";
export const DELETE = "request-delete";
export const UPLOAD = "request-upload";

const state = {};

const getters = {};

const actions = {
  [POST](context, { url, data }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          const error = ObjectPath.get(response, "response.data");
          if (error) {
            reject(error);
          } else {
            reject(response);
          }
        });
    });
  },
  [PUT](context, { url, data }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.put(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          const error = ObjectPath.get(response, "response.data");
          if (error) {
            reject(error);
          } else {
            reject(response);
          }
        });
    });
  },
  [GET](context, { url }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          const error = ObjectPath.get(response, "response.data");
          if (error) {
            reject(error);
          } else {
            reject(response);
          }
        });
    });
  },
  [QUERY](context, { url, data }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          const error = ObjectPath.get(response, "response.data");
          if (error) {
            reject(error);
          } else {
            reject(response);
          }
        });
    });
  },
  [PATCH](context, { url, data }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.patch(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          const error = ObjectPath.get(response, "response.data");
          if (error) {
            reject(error);
          } else {
            reject(response);
          }
        });
    });
  },
  [DELETE](context, { url, data }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          const error = ObjectPath.get(response, "response.data");
          if (error) {
            reject(error);
          } else {
            reject(response);
          }
        });
    });
  },
  [UPLOAD](context, { url, data }) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.upload(url, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          const error = ObjectPath.get(response, "response.data");
          if (error) {
            reject(error);
          } else {
            reject(response);
          }
        });
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
